import React from 'react'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { Link, graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import Hero from '../components/eco-clear/hero'
import Products from '../components/eco-clear/products'
import EcoClearTable from '../components/eco-clear/ecoclear-table-za'
import EndBanner from '../components/eco-clear/end-banner'
import localeCheck from '../components/utils/locale-check'
import countryFilter from '../components/utils/country-filter'

const EcoClear = ({
	data: {
		ecoclear,
		bannerXxsImage,
		bannerXsImage,
		bannerSmImage,
		bannerMdImage,
		bannerLgImage,
		product,
		productdetails,
		lastbanner,
		lastbannerMd,
		lastbannerSm,
	},
}) => {

	const locale = 'en-ZA'
	const defaultLocale = 'en-ZA'

	const [{ node }] = filterLocale(ecoclear.edges, locale)
	const [{ node: bannerxxs }] = filterLocale(bannerXxsImage.edges, locale)
	const [{ node: bannerxs }] = filterLocale(bannerXsImage.edges, locale)
	const [{ node: bannersm }] = filterLocale(bannerSmImage.edges, locale)
	const [{ node: bannermd }] = filterLocale(bannerMdImage.edges, locale)
	const [{ node: bannerlg }] = filterLocale(bannerLgImage.edges, locale)
	const [{ node: lastBanner }] = filterLocale(lastbanner.edges, locale)
	const [{ node: lastbannermd }] = filterLocale(lastbannerMd.edges, locale)
	const [{ node: lastbannersm }] = filterLocale(lastbannerSm.edges, locale)

	const productByLocale = (item, locale) => {
		return item.edges.filter(i => i.node.node_locale === locale)
	}

	const products = productByLocale(product, locale)
	const prodDetails = productByLocale(productdetails, locale)

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		slug,
		body: pagebody,
		banner: {
			mainActionText,
			mainActionUrl,
			secondActionText,
			secondActionUrl,
			description,
		},
	} = node

	const bannerSources = [
		{
			...bannerlg.backgroundImage.fluid,
			srcSet: `${bannerlg.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${bannerlg.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...bannermd.backgroundImage.fluid,
			srcSet: `${bannermd.backgroundImage.fluid.src} 1349w`,
			srcSetWebp: `${bannermd.backgroundImage.fluid.srcWebp} 1349w`,
			media: `(min-width: 1280px) and (max-width: 1349px)`,
		},
		{
			...bannersm.backgroundImage.fluid,
			srcSet: `${bannersm.backgroundImage.fluid.src} 1279w`,
			srcSetWebp: `${bannersm.backgroundImage.fluid.srcWebp} 1279w`,
			media: `(min-width: 1024px) and (max-width: 1279px)`,
		},
		{
			...bannerxs.backgroundImage.fluid,
			srcSet: `${bannerxs.backgroundImage.fluid.src} 1023w`,
			srcSetWebp: `${bannerxs.backgroundImage.fluid.srcWebp} 1023w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...bannerxxs.backgroundImage.fluid,
			srcSet: `${bannerxxs.backgroundImage.fluid.src} 767w`,
			srcSetWebp: `${bannerxxs.backgroundImage.fluid.srcWebp} 767w`,
			media: `(max-width: 767px)`,
		},
	]

	const lastBannerSources = [
		{
			...lastBanner.backgroundImage.fluid,
			srcSet: `${lastBanner.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${lastBanner.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...lastbannermd.backgroundImage.fluid,
			srcSet: `${lastbannermd.backgroundImage.fluid.src} 1023w`,
			srcSetWebp: `${lastbannermd.backgroundImage.fluid.srcWebp} 1023w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...lastbannersm.backgroundImage.fluid,
			srcSet: `${lastbannersm.backgroundImage.fluid.src} 525w`,
			srcSetWebp: `${lastbannersm.backgroundImage.fluid.srcWebp} 525w`,
			media: `(min-width: 525px) and (max-width: 767px)`,
		},
	]

	const checkedLocale = localeCheck(locale, defaultLocale)
	const prodCountry = countryFilter(products, locale)
	const prodDetailsCountry = countryFilter(prodDetails, locale)
	return (
		<div>
			<Layout>
				<Meta
					title={seoTitle}
					keywords={seoKeywords}
					description={seoDescription}
				/>
				<Hero
					{...{
						mainActionText,
						mainActionUrl,
						secondActionText,
						secondActionUrl,
						description,
						bannerSources
					}}
				/>
				<Products
					locale={locale}
					body={pagebody}
					products={prodCountry}
					productdetails={prodDetailsCountry}
					checkedLocale={checkedLocale}
				/>
				<EcoClearTable locale={locale}/>
				<EndBanner {...{ lastBannerSources, lastBanner }} />
			</Layout>
		</div>
	)
}

export const ecoClearQueryZA = graphql`
	query ecoClearQueryZA {
		ecoclear: allContentfulPage(
			filter: { contentful_id: { eq: "79rMRi4MYwCjhIzrYBaDPm" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
					banner {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						description {
							json
						}
					}
				}
			}
		}
		bannerXxsImage: allContentfulPage(filter: {contentful_id: {eq: "79rMRi4MYwCjhIzrYBaDPm"}}) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 768
							maxHeight: 500
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerXsImage: allContentfulPage(filter: {contentful_id: {eq: "79rMRi4MYwCjhIzrYBaDPm"}}) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 1023
							maxHeight: 600
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerSmImage: allContentfulPage(filter: {contentful_id: {eq: "79rMRi4MYwCjhIzrYBaDPm"}}) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 1279
							maxHeight: 1000
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerMdImage: allContentfulPage(filter: {contentful_id: {eq: "79rMRi4MYwCjhIzrYBaDPm"}}) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 1349
							maxHeight: 750
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerLgImage: allContentfulPage(filter: {contentful_id: {eq: "79rMRi4MYwCjhIzrYBaDPm"}}) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 1920
							maxHeight: 1080
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		product: allContentfulProduct(
			filter: {
				contentful_id: {
					in: ["T1yU9oqgjytkDENU9HfSE", "39AyHc4DweIs05mANJL6um", "5kD1zukfwlE9bJVcydezMp"]}
				},
			sort: {fields: popularity, order: ASC}
		) {
			edges {
				node {
					node_locale
					seoTitle
					seoDescription
					slug
					sku
					price
					model
					brand
					active
					series
					countryAvailability
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					images {
						description
						fluid(quality: 60, maxWidth: 500, maxHeight: 500) {
							src
							srcWebp
						}
					}
					active
				}
			}
		}
		productdetails: allContentfulProduct(
			filter: {
				contentful_id: {
					in: ["T1yU9oqgjytkDENU9HfSE", "39AyHc4DweIs05mANJL6um"]}
			},
			sort: { fields: popularity, order: DESC }
		) {
			edges {
				node {
					seoTitle
					node_locale
					countryAvailability
					body {
						json
					}
				}
			}
		}
		lastbanner: allContentfulBanner(
			filter: {contentful_id: {eq: "2VrOAWgu04wcjz7oCuD9So"}}
		) {
			edges {
				node {
					hook
					mainActionText
					mainActionUrl
					node_locale
					description {
						json
					}
					backgroundImage {
						fluid(
							maxWidth: 1700
							maxHeight: 580
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
				}
			}
		}
		lastbannerMd: allContentfulBanner(
			filter: {contentful_id: {eq: "2VrOAWgu04wcjz7oCuD9So"}}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 1400
							maxHeight: 580
							quality: 60
							cropFocus: TOP
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
				}
			}
		}
		lastbannerSm: allContentfulBanner(
			filter: {contentful_id: {eq: "2VrOAWgu04wcjz7oCuD9So"}}
		) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(
							maxWidth: 800
							maxHeight: 450
							quality: 60
							cropFocus: RIGHT
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
				}
			}
		}
	}
`

export default EcoClear
